import React, { FC, DOMAttributes, HTMLAttributes, createElement } from "react";
import classNames from "classnames";
import { t } from "@lingui/macro";

type Tag = "p" | "div";
interface Props
  extends HTMLAttributes<HTMLElement>,
    DOMAttributes<HTMLElement> {
  centered?: boolean;
  variant?: "default" | "warning";
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
  as?: Tag;
}

export const Paragraph: FC<Props> = ({
  children,
  centered,
  className = "",
  variant = "default",
  as = "p",
  size = "lg",
  ...props
}) => {
  const classes = classNames(
    "m-0 font-normal leading-normal",
    className,
    {
      "m-auto text-center": centered,
      "max-w-screen-lg": !className.includes("max-w"),
    },
    // variants
    {
      "text-gray-700": variant == "default",
      "text-yellow-600": variant === "warning",
    },
    //sizes
    {
      "text-xs md:text-xs": size === "xs",
      "text-sm md:text-sm": size === "sm",
      "text-base md:text-base": size === "sm",
      "text-base md:text-lg": size === "lg",
      "text-lg md:text-xl": size === "xl",
      "text-xl md:text-2xl": size === "2xl",
    }
  );

  // Normal texts with new lines & no links
  if (
    typeof children === "string" &&
    !children.includes("http") &&
    children.includes("\n")
  ) {
    const lines = children.split("\n");

    return (
      <div {...props} className={classes}>
        {lines.map((line, key) => (
          <div key={key}>
            {line && <p>{line}</p>}

            {/* To support extra line */}
            {!line && <br />}
          </div>
        ))}
      </div>
    );
  }

  // texts with links & new lines
  if (typeof children === "string" && children.includes("http")) {
    const lines = urlify(children).split("\n");

    return (
      <div {...props} className={classes}>
        {lines.map((line, key) => (
          <div key={key}>
            {line && line.includes("http") && (
              <div dangerouslySetInnerHTML={{ __html: line }} />
            )}
            {line && !line.includes("http") && <p>{line}</p>}

            {/* To suppor extra line */}
            {!line && <br />}
          </div>
        ))}
      </div>
    );
  }

  return createElement(as, { children, className: classes, ...props });
};

function urlify(text: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    if (isMaskedUrl(url)) {
      return url;
    }
    return (
      '<a target="_blank" href="' +
      url +
      '" title="' +
      t({
        id: "common.link.title",
        message: "GoWithGuide - Private Tours & Local Tour Guides",
      }) +
      '">' +
      "<u>" +
      url +
      "</u>" +
      "</a>"
    );
  });
}

function isMaskedUrl(text: string) {
  return text.includes("https://******") || text.includes("http://******");
}
