import { DateTime } from "luxon";

export const getDateFormatWithLocale = (
  isoDate: string,
  locale?: string,
  format = "MMM dd, yyyy",
  zone = "utc"
) => {
  return getDate(isoDate, zone).toFormat(format, {
    locale,
  });
};

export const getDateFormat = (
  isoDate: string,
  format = "MMM dd, yyyy",
  zone = "utc"
) => {
  return getDate(isoDate, zone).toFormat(format);
};

export const getLocalDateFormat = (
  isoDate: string,
  format = "MMM dd, yyyy",
  zone = "utc"
) => {
  return getLocalDate(isoDate, zone).toFormat(format);
};
export const getDate = (isoDate: string, zone = "utc") => {
  return DateTime.fromISO(isoDate, { zone });
};

export const getLocalDate = (isoDate: string, zone = "utc") => {
  return DateTime.fromISO(isoDate, { zone }).toLocal();
};
